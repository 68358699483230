<div
  class="flex justify-center items-center h-full"
  [ngClass]="[backgroundColor, stickerPosition, size, elementWidth, customMargin, borderRadius]"
>
  <p class="text-center px-1 py-[0.10rem]" [ngClass]="[fontSize]">
    <span class="text-white font-bold">
      {{ shortText }}
    </span>
  </p>
</div>
